import { urlService } from 'core-utilities';
import React from 'react';
import { Button, Link } from 'react-style-guide';
import { TranslateFunction } from 'react-utilities';
import { fireEvent } from 'roblox-event-tracker';
import { gameDetailsPage, homePage } from '../../common/constants/configConstants';
import { FeatureGameDetails } from '../../common/constants/translationConstants';
import { TGetGameDetails, TRefundPolicy } from '../../common/types/bedev1Types';
import { sendRequestRefundEvent } from '../utils/refundButtonUtils';

type TRefundPolicyTextProps = {
  policyData: TRefundPolicy;
};

const RefundPolicyText: React.FC<TRefundPolicyTextProps> = ({ policyData }) => {
  const { policyText, learnMoreBaseUrl, locale, articleId } = policyData;
  const { linkStartDelimiter, linkEndDelimiter } = homePage;

  const learnMoreLink = urlService.getHelpDeskUrl(locale, articleId, learnMoreBaseUrl);

  const policyTextLinkRegex = new RegExp(`${linkStartDelimiter}|${linkEndDelimiter}`);

  const parts = policyText.split(policyTextLinkRegex);

  return (
    <p>
      {parts.map((part, index) => {
        // The middle part (index 1) should be wrapped in Link. Also this
        // function only handles the first link in the policy text.
        if (index === 1) {
          return (
            <Link cssClasses='text-link' url={learnMoreLink}>
              {part}
            </Link>
          );
        }
        return part;
      })}
    </p>
  );
};

export type TExperienceRefundProps = {
  gameDetails: TGetGameDetails;
  translate: TranslateFunction;
};

const ExperienceRefund: React.FC<TExperienceRefundProps> = ({ gameDetails, translate }) => {
  const { refundLink, refundPolicy, rootPlaceId } = gameDetails;
  return (
    <div className='experience-refund-container'>
      <div className='container-header'>
        <h2>{translate(FeatureGameDetails.HeadingRefund)}</h2>
      </div>
      <div className='experience-refund-content'>
        {!!refundPolicy && <RefundPolicyText policyData={refundPolicy} />}
        {refundLink && (
          <div className='experience-refund-button'>
            <Link url={refundLink}>
              <Button
                variant='secondary'
                onClick={() => {
                  if (rootPlaceId) {
                    sendRequestRefundEvent(rootPlaceId);
                  } else {
                    fireEvent(gameDetailsPage.requestRefundError);
                  }
                }}>
                <span>{translate(FeatureGameDetails.ActionRequestRefund)}</span>
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExperienceRefund;
