import React, { useEffect, useState, useCallback } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { Intl } from 'Roblox';
import experienceGuidelinesService from '../services/experienceGuidelinesService';
import gameGuidelinesTranslationsConfig from '../translation.config';
import metadataConstants from '../../gameDetails/constants/metadataConstants';
import gameGuidelinesConstants from '../constants/gameGuidelinesConstants';
import { ExperienceGuidelines, AgeRecommendationDetailsResponse } from '../types';
import urlConstants from '../constants/urlConstants';
import assetTextFilterSettingsService from '../services/assetTextFilterSettingsService';

export type AgeRecommendationTitleProps = {
  isDisplayAgeRecommendationDetails: boolean;
  translate: WithTranslationsProps['translate'];
};

export const AgeRecommendationTitle = ({
  isDisplayAgeRecommendationDetails,
  translate
}: AgeRecommendationTitleProps): JSX.Element => {
  const [isBusy, setIsBusy] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [contentContainsStrongLanguage, setContentContainsStrongLanguage] = useState(false);
  const [communicationContainsStrongLanguage, setCommunicationContainsStrongLanguage] = useState(
    false
  );
  const [experienceGuidelines, setExperienceGuidelines] = useState<ExperienceGuidelines | null>(
    null
  );
  const [headerDisplayName, setHeaderDisplayName] = useState<string | null>(null);

  const { universeId = '' } = metadataConstants.metadataData() || {};

  const getCommunicationStrongLanguageSettingForUniverse = useCallback(async () => {
    try {
      const atfsResponse = await assetTextFilterSettingsService.getAssetTextFilterSettings(
        universeId
      );
      setCommunicationContainsStrongLanguage(atfsResponse?.Profanity === true);
    } catch {
      setCommunicationContainsStrongLanguage(false);
    }
  }, [universeId]);

  const getAgeGuidelinesForUniverse = useCallback(async () => {
    setIsBusy(true);
    try {
      const egsResponse = await experienceGuidelinesService.getAgeRecommendation(universeId);
      const ageRecommendation = egsResponse.data as AgeRecommendationDetailsResponse;

      setHeaderDisplayName(ageRecommendation.headerDisplayName);
      if (ageRecommendation.ageRecommendationDetails?.summary.ageRecommendation == null) {
        setExperienceGuidelines(null);
        return;
      }
      let descriptorDisplayNames = ageRecommendation.ageRecommendationDetails.descriptorUsages
        ?.map(usage => usage.descriptor.displayName)
        .join(', ');

      /* 
      Two special cases when descriptorDisplayNames is null
      1. If the ageRec is All Ages, and there are no descriptors because
         the game doesn't have any, then we should show the suitable
         for everyone text.
      2. If we get an age rec with no descriptors which is an error, we
         still show the age recommendation we have, just without any descriptors.
      */
      if (!descriptorDisplayNames) {
        if (ageRecommendation.ageRecommendationDetails.summary.ageRecommendation.minimumAge === 0) {
          descriptorDisplayNames = gameGuidelinesConstants.SuitableForAllAgesText;
        } else {
          descriptorDisplayNames = '';
        }
      }

      const newExperienceGuidelines: ExperienceGuidelines = {
        descriptorDisplayNames,
        ageRecommendationBracket:
          ageRecommendation.ageRecommendationDetails.summary.ageRecommendation
            .displayNameWithHeaderShort ??
          ageRecommendation.ageRecommendationDetails.summary.ageRecommendation.displayName
      };

      setExperienceGuidelines(newExperienceGuidelines);
      const strongLanguageDescriptor = ageRecommendation.ageRecommendationDetails.descriptorUsages?.find(
        descriptor => descriptor.name === 'strong-language'
      );
      setContentContainsStrongLanguage(strongLanguageDescriptor?.contains === true);
    } catch {
      setHasError(true);
      setExperienceGuidelines(null);
      setContentContainsStrongLanguage(false);
      setHeaderDisplayName(null);
    } finally {
      setIsBusy(false);
    }
  }, [universeId]);

  const updateState = useCallback(async () => {
    try {
      setIsBusy(true);
      await getAgeGuidelinesForUniverse();
      await getCommunicationStrongLanguageSettingForUniverse();
    } catch {
      setHasError(true);
      setExperienceGuidelines(null);
      setCommunicationContainsStrongLanguage(false);
      setHeaderDisplayName(null);
      setContentContainsStrongLanguage(false);
    } finally {
      setIsBusy(false);
    }
  }, [getAgeGuidelinesForUniverse, getCommunicationStrongLanguageSettingForUniverse]);

  // update state on page load
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateState();
  }, [updateState]);

  const locale = new Intl().getRobloxLocale();

  if (isDisplayAgeRecommendationDetails) {
    if (experienceGuidelines) {
      return (
        <div className='age-rating-details col-xs-12 section-content'>
          <a
            className='age-rating-age-bracket text-lead text-link'
            href={urlConstants.experienceGuidelinesPolicyPageUrl(locale)}>
            {experienceGuidelines.ageRecommendationBracket}
          </a>
          <span className='age-rating-display-name text'>
            {experienceGuidelines.descriptorDisplayNames}
          </span>
        </div>
      );
    }
    if (!isBusy) {
      return (
        <div className='age-rating-details col-xs-12 section-content'>
          <a
            className='age-rating-age-bracket text-lead text-link'
            href={urlConstants.experienceGuidelinesPolicyPageUrl(locale)}>
            {headerDisplayName ??
              translate(gameGuidelinesConstants.ContentMaturityTextKey) ??
              gameGuidelinesConstants.ContentMaturityText}
          </a>
          <span className='age-rating-display-name text'>
            {translate(gameGuidelinesConstants.AgeGuidelinesUnavailableKey) ??
              gameGuidelinesConstants.AgeGuidelinesUnavailable}
          </span>
        </div>
      );
    }
  }

  if (
    experienceGuidelines &&
    (communicationContainsStrongLanguage || contentContainsStrongLanguage)
  ) {
    const ageRecommendationWithWarning = translate(
      gameGuidelinesConstants.AgeGuidelinesWithWarningKey,
      {
        guideline: experienceGuidelines.ageRecommendationBracket
      }
    );
    return (
      <a className='age-recommendation-title text' href='#game-age-recommendation-details'>
        {
          ageRecommendationWithWarning !== ''
            ? ageRecommendationWithWarning
            : experienceGuidelines.ageRecommendationBracket // default to previous behavior if translation was empty
        }
      </a>
    );
  }
  if (experienceGuidelines) {
    return (
      <a
        className='age-recommendation-title text'
        href='#game-age-recommendation-details-container'>
        {experienceGuidelines.ageRecommendationBracket}
      </a>
    );
  }

  return <React.Fragment />;
};

export default withTranslations<{ isDisplayAgeRecommendationDetails: boolean }>(
  AgeRecommendationTitle,
  gameGuidelinesTranslationsConfig
);
