import React, { useEffect, useState } from 'react';
import { PlayButton as RobloxPlayButton } from 'Roblox';
import { Loading } from 'react-style-guide';
import metadataConstants from '../constants/metadataConstants';
import eventStreamConstants, {
  EventStreamMetadata,
  SessionInfoType
} from '../../common/constants/eventStreamConstants';
import usePageReferralTracker from '../../common/hooks/usePageReferralTracker';
import { AttributionType, getAttributionId } from '../../common/utils/attributionUtils';
import { PageContext } from '../../common/types/pageContext';
import getExperienceAffiliateReferralUrlParams from '../../common/utils/getExperienceAffiliateReferralUrlParams';
import experimentConstants from '../../common/constants/experimentConstants';
import bedev2Services from '../../common/services/bedev2Services';
import useGetAppPolicyData from '../../common/hooks/useGetAppPolicyData';

const {
  usePlayabilityStatus,
  shouldShowUnplayableButton,
  DefaultPlayButton,
  Error
} = RobloxPlayButton;

export default function PlayButton(): JSX.Element {
  const { universeId = '', rootPlaceId = '', privateServerLinkCode = '', gameInstanceId = '' } =
    metadataConstants.metadataData() || {};
  const [playabilityStatus, refetchPlayabilityStatus] = usePlayabilityStatus(universeId);
  const attributionId = getAttributionId(AttributionType.GameDetailReferral);
  const { linkCode, linkType } = getExperienceAffiliateReferralUrlParams(
    window.location.toString()
  );
  const referralParams = usePageReferralTracker(
    eventStreamConstants.gameDetailReferral,
    [
      EventStreamMetadata.IsAd,
      EventStreamMetadata.Page,
      EventStreamMetadata.PlaceId,
      EventStreamMetadata.UniverseId,
      EventStreamMetadata.SortPos,
      EventStreamMetadata.Position,
      EventStreamMetadata.GameSetTypeId,
      EventStreamMetadata.GameSetTargetId,
      EventStreamMetadata.FriendId,
      EventStreamMetadata.NumberOfLoadedTiles,
      EventStreamMetadata.AppliedFilters,
      SessionInfoType.DiscoverPageSessionInfo,
      SessionInfoType.HomePageSessionInfo,
      SessionInfoType.GameSearchSessionInfo
    ],
    ['privateServerLinkCode', 'placeId', 'launchData', 'gameInstanceId', 'referralUrl'],
    {
      [EventStreamMetadata.PlaceId]: rootPlaceId,
      [EventStreamMetadata.UniverseId]: universeId,
      [EventStreamMetadata.ShareLinkType]: linkType,
      [EventStreamMetadata.ShareLinkId]: linkCode
    }
  );

  const { layerNames, defaultValues } = experimentConstants;
  const [hasUpdatedPlayButtonsIxp, setHasUpdatedPlayButtonsIxp] = useState<boolean | undefined>(
    undefined
  );
  const [hasUpdatedPlayButtonsVpcIxp, setHasUpdatedPlayButtonsVpcIxp] = useState<
    boolean | undefined
  >(undefined);
  const [isFetchingIxp, setIsFetchingIxp] = useState<boolean>(false);

  const { shouldShowVpcPlayButtonUpsells, isFetchingPolicy } = useGetAppPolicyData();

  useEffect(() => {
    setIsFetchingIxp(true);
    bedev2Services
      .getExperimentationValues(layerNames.playButton, defaultValues.playButton)
      .then(data => {
        setHasUpdatedPlayButtonsIxp(data.HasUpdatedPlayButtons === true);
        setHasUpdatedPlayButtonsVpcIxp(data.HasUpdatedPlayButtonsVpc === true);
      })
      .catch(() => {
        setHasUpdatedPlayButtonsIxp(defaultValues.playButton.HasUpdatedPlayButtons);
        setHasUpdatedPlayButtonsVpcIxp(defaultValues.playButton.HasUpdatedPlayButtonsVpc);
      })
      .finally(() => {
        setIsFetchingIxp(false);
      });
  }, [layerNames.playButton, defaultValues.playButton]);

  if (isFetchingIxp || isFetchingPolicy) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <DefaultPlayButton
        placeId={rootPlaceId}
        rootPlaceId={rootPlaceId}
        universeId={universeId}
        privateServerLinkCode={privateServerLinkCode}
        gameInstanceId={gameInstanceId}
        refetchPlayabilityStatus={refetchPlayabilityStatus}
        playabilityStatus={playabilityStatus}
        hideButtonText={false}
        disableLoadingState={false}
        eventProperties={{
          [SessionInfoType.DiscoverPageSessionInfo]: referralParams.discoverPageSessionInfo,
          [SessionInfoType.GameSearchSessionInfo]: referralParams.gameSearchSessionInfo,
          [SessionInfoType.HomePageSessionInfo]: referralParams.homePageSessionInfo,
          [EventStreamMetadata.AttributionId]: attributionId,
          [EventStreamMetadata.Page]: referralParams.page,
          [EventStreamMetadata.SortPos]: referralParams.sortPos,
          [EventStreamMetadata.GameSetTypeId]: referralParams.gameSetTypeId,
          [EventStreamMetadata.UniverseId]: universeId,
          [EventStreamMetadata.AppliedFilters]: referralParams.appliedFilters,
          [EventStreamMetadata.PlayContext]: PageContext.GameDetailPage
        }}
        hasUpdatedPlayButtonsIxp={hasUpdatedPlayButtonsIxp}
        hasUpdatedPlayButtonsVpcIxp={hasUpdatedPlayButtonsVpcIxp}
        shouldShowVpcPlayButtonUpsells={shouldShowVpcPlayButtonUpsells}
      />
      {
        // for unplayable experiences, show the reason it's unplayable underneath the unplayable button
        shouldShowUnplayableButton(
          playabilityStatus,
          shouldShowVpcPlayButtonUpsells,
          hasUpdatedPlayButtonsVpcIxp
        ) && (
          <Error
            playabilityStatus={playabilityStatus}
            errorClassName={hasUpdatedPlayButtonsIxp ? 'error-message treatment' : 'error-message'}
          />
        )
      }
    </React.Fragment>
  );
}
