import { eventStreamService } from 'core-roblox-utilities';
import eventStreamConstants from '../../common/constants/eventStreamConstants';

export const sendRequestRefundEvent = (placeId: number) => {
  const eventStreamParams = eventStreamConstants.requestRefundClick({
    placeId
  });

  eventStreamService.sendEvent(...eventStreamParams);
};

export default {
  sendRequestRefundEvent
};
